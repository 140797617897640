#bottom-container {
     display: flex !important;
     flex-direction: column !important;
}

.list-group {
     display: flex !important;
     flex-direction: row !important;
     padding-left: 0 !important;
     margin-bottom: 0 !important;
     align-content: center !important;
     justify-content: center !important;
}

#top-container {
     margin-bottom: 20px !important;
}

.list-group-item {
     margin-bottom: -2rem !important;
     font-size: 2rem !important;
}

.MuiButton-startIcon {
     display: inherit;
     margin-left: 0px !important;
     margin-right: 0px !important;
}

.yAxis {
     writing-mode: vertical-lr;
     -webkit-transform: rotate(-180deg);
     -moz-transform: rotate(-180deg);
     display: flex;
     width: 100%;
     flex-wrap: nowrap;
     align-content: center;
     justify-content: center;
     align-items: flex-end;
     margin-left: -30px;
     margin-top: -250px;
     font-size: 1.6rem !important;
}

.yAxisChartName {
     writing-mode: vertical-rl;
     -webkit-transform: rotate(-180deg);
     -moz-transform: rotate(-180deg);
     display: flex;
     width: 100%;
     flex-wrap: nowrap;
     align-content: center;
     justify-content: center;
     align-items: flex-start;
     margin-left: -30px;
     margin-top: -50%;
     font-size: 1.6rem !important;
}

.h3 {
     margin-bottom: 100px;
}

.xAxis {
     display: flex;
     align-content: center;
     justify-content: center;
     align-items: center;
     margin-top: 10px;
     font-size: 1.6rem !important;
     /* color: blue; */
}

.textStyle {
     font-size: 1.6rem !important;
     display: flex;
     justify-content: left;
     align-items: center;
}

.dx-c-bs4-label {
     color: black;
}

.chart-two-tm-tab .MuiBox-root {
     padding: 0px !important;
}
.chart-two-tm-tab .text-muted {
     color: #000000 !important;
}
.chart-two-tm .MuiPaper-root {
     padding: 0px !important;
}

.chart-two-tm .MuiAppBar-colorPrimary {
     background-color: #f8f8f8 !important;
}

.chart-two-tm .MuiPaper-elevation4 {
     box-shadow: none !important;
}
.chart-two-tm .MuiTabs-flexContainer .MuiButtonBase-root {
     background-color: #d9ead3 !important;
     border-radius: 10px 10px 0px 0px !important;
}
.chart-two-tm .MuiTabs-flexContainer .MuiButtonBase-root .MuiTab-wrapper {
     color: black !important;
     font-size: 15px !important;
}
