.label-must-input {

    font-size: 1vw;
    color: red;
}

.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;

    font-size: 1vw;
    line-height: normal;
}

.search-select {
    position: relative;
    display: inline-block;
}

.input-search-select {}

.search-select-content {
    display: none;
    position: absolute;
    background-color: #fff;
    /* min-width: 160px; */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    /* padding: 12px 16px; */
    z-index: 1;
    max-height: 25vh;
    overflow-y: scroll;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    max-width: 83%;
}

.search-select:focus-within .search-select-content {
    display: block;
}

.selections {
    padding: 8px 12px;
    border: none;
    background-color: #fff;
    width: 100%;
}

.search-select-content:hover {
    background-color: #f9f9f9;
    cursor: pointer;
}

.selections:hover {
    background-color: #f9f9f9;
    cursor: pointer;
}