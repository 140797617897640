#font-color {
  color: deepskyblue;
  font-size: large;
}

#s-graph-right-1 {
  background-color: white;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
#button-g-r-l {
  border: none;
  background: white;
  font-size: medium;
  outline: none;
  width: 150px;
  height: 40px;
}
#button-g-r-r {
  outline: none;
  border: none;
  font-size: medium;
  width: 150px;
  height: 40px;
}
#flex-end-MY {
  display: flex;
  justify-content: flex-end;
}
#block-intime {
  width: 10px;
  height: 10px;
  background-color: #467ac8;
  margin-right: 5px;
}
#block-outtime {
  width: 10px;
  height: 10px;
  background-color: orange;
  margin-right: 5px;
}
#block-intime-r {
  width: 20px;
  height: 3px;
  background-color: #467ac8;
  border-radius: 5px;
  margin-right: 5px;
}
#block-outtime-r {
  width: 20px;
  height: 3px;
  background-color: orange;
  border-radius: 5px;
  margin-right: 5px;
}

#position-graph {
	background-color: white;
	border-radius: 5px;
	border: 1px solid #dededf
}
#font-color {
	color: deepskyblue;
	font-size: large;
}
#border-right {
	border-right: 1px solid #dededf;
}
#border-bottom {
	width: 100%;
	justify-content: center;
	display: flex;
}
#border-solid {
	border-top: 1px solid #dededf;
	display: flex;
}

#border-right-1 {
	display: flex;
	padding-bottom: 15px;
	padding-top: 15px;
	margin-top: 10px;
}
#border-right-1-1 {
	display: flex;
	width: 50%;
	justify-content: center;
	flex-direction: column;
}

