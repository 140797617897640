.div-graph {
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
}

#div-button {
     border-radius: 5px;
     border: none;
     color: white;
     background-color: #467ac8;
     width: 100px;
     height: 25px;
     outline: none;
}

.botton-search {
     width: 7.5rem;
     height: 2.5rem;
     border: none;
     border-radius: 5px;
     color: white;
     background-color: #467ac8;
}

#div-download {
     width: 75px;
     height: 30px;
     border: none;
     border-radius: 5px;
     color: white;
     background-color: #b8358d;
}

#div-datepicker {
     width: 90px;
     height: 25px;
     border-color: #fbfbfb;
     border-radius: 5px;
     border-width: 0.5px;
     outline: none;
     padding-left: 5px;
     background-color: #fbfbfb;
}
#div-datepicker-calendar {
     width: 200px;
     height: 25px;
     /* border-color: #fbfbfb; */
     border-radius: 4px;
     /* border-width: 0.5px; */
     outline: none;
     /* padding-left: 5px; */
     /* background-color: #fbfbfb; */
     font-size: 1.75rem;
     box-shadow: inset 0 2px 2px #e9e9e9;
     border: 1px solid #aeaeae;
}

.react-datepicker-popper {
     position: absolute;
     will-change: transform;
     top: 0px;
     left: 0px;
     transform: translate3d(15px, 25px, 0px);
     z-index: 5 !important;
}

.auto-style-graph .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
     background: white !important;
     font-size: 1.3em !important;
}

.auto-style-graph .MuiAutocomplete-root {
     width: 81% !important;
     margin-right: 5px !important;
}
.auto-style-graph .MuiSvgIcon-root {
     font-size: x-large !important;
}
.auto-style-graph .MuiChip-root {
     font-size: 1em !important;
     height: 28px !important;
}
