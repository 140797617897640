.btn-form {
    color: #fff;
    background-color: #467ac8;
    border-color: #467ac8;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .375rem 3.75rem;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;
}

.btn-form:hover {
    cursor: pointer;
    color: #fff;
    background-color: #b8358d;
    text-decoration: none;
}

.btn-form:focus {
    outline: none;
}

.btn-exit-hover:hover {
    transition-duration: 0.2s;
    background-color: #b8358d !important;
}

.bed-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
}

.bed-table th,
.bed-table td {
    text-align: left;
    padding: 8px;
}

.bed-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.btn-excel {
    border-top: 0px;
    border-right: 2px solid #284674;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #284674;
    margin-right: 5px;
    /* box-shadow: 1.5px 0px #284674; */
    color: #fff;
    background-color: #467ac8;
    /* border-color: #467ac8; */
    /* display: inline-block; */
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 3.75rem;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.btn-excel:hover {
    cursor: pointer;
    color: #fff;
    background-color: #b8358d;
    text-decoration: none;
}

.btn-excel:focus {
    outline: none;
}

#btn-form-excel {
    border-top: 0px;
    border-right: 2px solid #227547;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #227547;
    margin-right: 5px;
    /* box-shadow: 1.5px 0px #284674; */
    color: #fff;
    background-color: #5d9a78;
    /* border-color: #467ac8; */
    /* display: inline-block; */
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 2px 5px;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

#btn-form-excel:hover {
    cursor: pointer;
    color: #fff;
    background-color: #b8358d;
    text-decoration: none;
}

#btn-form-excel:focus {
    outline: none;
}