@media screen and (min-width: 767px) and (max-width: 950px) {
     .font-size-calendar {
          font-size: medium !important;
     }
     .font-size-calendar .fc-dayGridMonth-button {
          font-size: medium !important;
     }
     .font-size-calendar .fc-timeGridDay-button {
          font-size: medium !important;
     }
     .font-size-calendar .btn-group .fc-prev-button {
          font-size: medium !important;
     }
     .font-size-calendar .btn-group .fc-next-button {
          font-size: medium !important;
     }
     .font-size-calendar .fc-today-button {
          font-size: medium !important;
     }
     .font-size-calendar .fc-popover {
          font-size: medium !important;
     }
     .font-size-calendar .fc-popover .fc-popover-header {
          font-size: medium !important;
     }
}
@media screen and (min-width: 951px) {
     .font-size-calendar {
          font-size: large !important;
     }
     .font-size-calendar .fc-dayGridMonth-button {
          font-size: large !important;
     }
     .font-size-calendar .fc-timeGridDay-button {
          font-size: large !important;
     }
     .font-size-calendar .btn-group .fc-prev-button {
          font-size: large !important;
     }
     .font-size-calendar .btn-group .fc-next-button {
          font-size: large !important;
     }
     .font-size-calendar .fc-today-button {
          font-size: large !important;
     }
     .font-size-calendar .fc-popover {
          font-size: large !important;
     }
     .font-size-calendar .fc-popover .fc-popover-header {
          font-size: large !important;
     }
}

.font-size-calendar button:focus {
     outline: unset !important;
     box-shadow: unset !important;
}

.font-size-calendar .fc .fc-bg-event .fc-event-title {
     color: black !important;
     font-weight: bold !important;
     font-style: unset !important;
}
.font-size-calendar .popover {
     height: auto !important;
     max-height: 350px !important;
     overflow: auto !important;
}
.font-size-calendar-admin .fc-event-title,
.font-size-calendar-admin .fc-sticky {
     white-space: break-spaces !important;
}
.custom-datepicker .react-datepicker-popper {
     width: 200px !important;
     left: 3px !important;
}
.custom-datepicker .react-datepicker-popper .react-datepicker {
     width: 100% !important;
}
.custom-datepicker .react-datepicker-popper .react-datepicker .react-datepicker__triangle {
     margin-left: 40px !important;
}
.custom-datepicker .react-datepicker-popper .react-datepicker .react-datepicker__month-container {
     width: 100% !important;
}
.custom-datepicker .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header,
.custom-datepicker .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker-year-header {
     font-size: 1.3rem !important;
}
.custom-datepicker .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month {
     font-size: 1.3rem !important;
}
.custom-datepicker .react-datepicker-popper .react-datepicker .react-datepicker__navigation--previous {
     border-right-color: black !important;
}
.custom-datepicker .react-datepicker-popper .react-datepicker .react-datepicker__navigation--next {
     border-left-color: black !important;
}

.auto-style .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
     background: white !important;
     font-size: 1.3em !important;
}
.auto-style .MuiAutocomplete-root {
     width: 100% !important;
}
.auto-style .MuiSvgIcon-root {
     font-size: x-large !important;
}
.auto-style .MuiChip-root {
     font-size: 1em !important;
     height: 28px !important;
}
.fc-event-main {
     cursor: pointer !important;
}
.fc-daygrid-day:hover {
     background: rgba(188, 232, 241) !important;
}
