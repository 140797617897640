.div-customer-detail {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.btn-back {
    width: max-content;
    color: #333;
    font-size: 1.2rem;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.btn-back:hover {
    color: #999;
    cursor: pointer;
}