#label-result {
    font-size: 1.6rem;
    color: #467ac8;
    user-select: none;
    font-weight: 100;
}

#div-vh {
    height: 66vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#div-button-results {
    background-color: white;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#div-label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border-top: 0px;
    border-right: 2px solid #954a9f;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    border-bottom: 2px solid #954a9f;
    margin-right: 5px;
    /* box-shadow: 1.5px 0px #284674; */
    color: #fff;
    background-color: #bf4c9a;
    /* border-color: #467ac8; */
    /* display: inline-block; */
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 2px 5px;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    cursor: pointer;
}

#btn-submit {
    color: #fff;
    background-color: #467ac8;
    border-color: #467ac8;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

#h3 {
    font-weight: bold;
}

#div-solid {
    border: 2px dashed #797777;
    border-radius: 3px;
    background-color: white;
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

#div-file {
    display: flex;
    width: 50%;
    justify-content: space-between;
}

#div-list {
    display: flex;
    width: 50%;
}

#div-listdata {
    width: 33%;
}

#btn-cancal {
    cursor: pointer;
    padding-bottom: 0.2rem;
}

#btn-lare-upload {}

@media only screen and (min-width: 768px) {
    .width-input {
        width: 5rem;
    }

    .width-input-amount {
        width: 3rem;
        text-align: center;
        border: 1px solid #ced4da;
    }

    .btn-plus {
        background-color: #ced4da;
        border: none;
        margin-right: 1px;
    }

    .btn-minus {
        background-color: #ced4da;
        border: none;
        margin-left: 1px;
    }

    .disable-input input[type=number]::-webkit-inner-spin-button,
    .disable-input input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@media only screen and (max-width: 767px) {
    .width-input {
        width: 4rem;
    }

    .width-input-amount {
        width: 3rem;
        text-align: center;
        border: 1px solid #ced4da;
    }

    .btn-plus {
        background-color: #ced4da;
        border: none;
        margin-right: 1px;
    }

    .btn-minus {
        background-color: #ced4da;
        border: none;
        margin-left: 1px;
    }

    .disable-input input[type=number]::-webkit-inner-spin-button,
    .disable-input input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}