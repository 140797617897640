.div-progress {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img-tool {
    width: 8vw;
    height: 8vw;
    object-fit: contain;
}

.label-tool {
    margin-top: 0.5vh;
    font-size: 1.5vw;
    font-weight: bold;
    color: #b8358d;
}

@media screen and (max-width: 991px) {
    .div-progress {
        width: 100%;
        height: 70vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .img-tool {
        width: 8vh;
        height: 8vh;
        object-fit: contain;
    }
    
    .label-tool {
        margin-top: 0.5vh;
        font-size: 1.5vh;
        font-weight: bold;
        color: #b8358d;
    }
}

@media screen and (max-width: 1024px) and (min-width: 992px) { 
    .div-progress {
        width: 100%;
        height: 70vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .img-tool {
        width: 11vw;
        height: 11vw;
        object-fit: contain;
    }
    
    .label-tool {
        margin-top: 0.5vh;
        font-size: 1.8vw;
        font-weight: bold;
        color: #467ac8;
    }
}