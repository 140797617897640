.EmptyListShow {
     width: 100%;
     height: 20vh;
     display: flex;
     align-items: center;
     justify-content: center;
     font-family: "sukhumvit-light";
}

.column-search {
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     align-items: center;
}

.row-search {
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
}

.button-search {
     width: auto;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.5rem 2rem;
     background-color: #467ac8;
     border: none;
     border-radius: 5px;
     font-size: 1.2rem;
     color: #fff;
     margin-left: 1rem;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
}

.button-search:hover {
     transition-duration: 0.1s;
     transform: scale(1.1);
     box-shadow: 4px 4px 1px #cfcfdd;
     cursor: pointer;
     background-color: #b8358d;
}

.button-reset {
     width: auto;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.5rem 2rem;
     background-color: #7bc8c6;
     border: none;
     border-radius: 5px;
     font-size: 1.2rem;
     color: #fff;
     margin-left: 1rem;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
}

.button-reset:hover {
     transition-duration: 0.1s;
     transform: scale(1.1);
     box-shadow: 4px 4px 1px #cfcfdd;
     cursor: pointer;
     background-color: #66bfb8;
}

.img-icon {
     width: 1.5rem;
     height: 1.5rem;
     object-fit: contain;
     cursor: pointer;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
}

.img-icon:hover {
     cursor: pointer;
}

.div-center {
     width: 100%;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
}

.icon-location {
     color: #467ac8;
}

.button-status-0 {
     width: 8rem;
     height: 100%;
     background-color: #fff;
     border: 1px solid #e8ad6a;
     font-size: 1rem;
     font-weight: 600;
     color: #e8ad6a;
     border-radius: 1rem;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.15rem 0;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
     cursor: pointer;
}
.button-status-1 {
     width: 8rem;
     height: 100%;
     background-color: #29acc7;
     border: 1px solid #29acc7;
     font-size: 1rem;
     font-weight: 600;
     color: #fff;
     border-radius: 1rem;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.15rem 0;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
     cursor: pointer;
}
.button-status-2 {
     width: 8rem;
     height: 100%;
     background-color: #584fff;
     border: 1px solid #584fff;
     font-size: 1rem;
     font-weight: 600;
     color: #fff;
     border-radius: 1rem;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.15rem 0;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
     cursor: pointer;
}
.button-status-3 {
     width: 8rem;
     height: 100%;
     background-color: #ff6442;
     border: 1px solid #ff6442;
     font-size: 1rem;
     font-weight: 600;
     color: #fff;
     border-radius: 1rem;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.15rem 0;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
     cursor: pointer;
}
.button-status-4 {
     width: 8rem;
     height: 100%;
     background-color: #b22fb7;
     border: 1px solid #b22fb7;
     font-size: 1rem;
     font-weight: 600;
     color: #fff;
     border-radius: 1rem;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.15rem 0;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
     cursor: pointer;
}
.button-status-5 {
     width: 8rem;
     height: 100%;
     background-color: #28a745;
     border: 1px solid #28a745;
     font-size: 1rem;
     font-weight: 600;
     color: #fff;
     border-radius: 1rem;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.15rem 0;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
     cursor: pointer;
}
.button-status-6 {
     width: 8rem;
     height: 100%;
     background-color: #e1ad0f;
     border: 1px solid #e1ad0f;
     font-size: 1rem;
     font-weight: 600;
     color: #fff;
     border-radius: 1rem;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.15rem 0;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
     cursor: pointer;
}
.button-status-99 {
     width: 8rem;
     height: 100%;
     background-color: #e25050;
     border: 1px solid #e25050;
     font-size: 1rem;
     font-weight: 600;
     color: #fff;
     border-radius: 1rem;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.15rem 0;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
     cursor: pointer;
}

.select-page-size {
     background-color: transparent;
     border-color: #467ac8;
     color: #467ac8;
}

.button-page {
     background-color: #467ac8;
     border-color: #467ac8;
     display: inline-block;
     font-weight: 400;
     color: #fff;
     text-align: center;
     vertical-align: middle;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     padding: 0.375rem 0.75rem;
     margin: 0 0.2rem;
     font-size: 1rem;
     line-height: 1.5;
     border-radius: 0.25rem;
     -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button-page:hover {
     cursor: pointer;
     background-color: #b8358d;
     border-color: #b8358d;
}
.swal-btn-cancel {
     background-color: red !important;
     color: white !important;
}
.swal-btn-cancel {
     background-color: red !important;
     color: white !important;
}
.swal-btn-confirm {
     background-color: green !important;
     color: white !important;
}
