.div-row-space {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.div-row-subject {
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ic-subject {
  width: 1.8rem;
  height: 1.8rem;
  color: #467ac8;
  margin-right: 1rem;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.label-subject {
  font-size: 1.8rem;
  color: #467ac8;
  font-weight: 500;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.ic-btn-blue-subject {
  color: #fff;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.btn-blue-subject {
  width: auto;
  height: 100%;
  font-size: 1.2rem;
  color: #fff;
  background-color: #467ac8;
  border: none;
  border-radius: 0.3rem;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
}

.btn-blue-subject:hover {
  cursor: pointer;
  background-color: #b8358d;
}

.div-card-detail {
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border: none;
  border-radius: 1rem;
  padding: 1rem;
}

.div-column-detail {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.label-detail {
  width: 100%;
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
  color: #467ac8;
}

.avatar-card-detail {
  margin-top: 1vh;
  vertical-align: middle;
  width: 8vw;
  height: 10vw;
  object-fit: cover;
  border-radius: 5px;
}

.card-detail-data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.card-detail-sub-data {
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  flex-direction: row;
}

.card-detail-sub-data-font {
  /* font-size: 1vw; */
  color: #000;
}

.card-history-contain-icon {
  background-color: transparent;
  width: 2vw;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.card-history-icon {
  width: 1.3vw;
  height: 1.3vw;
}

.empty-list-history {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vw;
}

.label-table-history {
  font-size: 1vw;
  color: #000;
  margin-top: 1vw;
}

.card-history-table-hover {
  background-color: transparent;
}
.card-history-table-hover:hover {
  cursor: pointer;
  background-color: #eff7fd;
  color: #000;
}
