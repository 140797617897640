.avatar-card-detail-table {
    margin-top: 1vh;
    vertical-align: middle;
    width: 7.5rem;
    height: 10rem;
    object-fit: cover;
    border-radius: 5px;
}

.div-table-customer-detail {
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: none;
    border-radius: 1rem;
    padding: 1rem;
}

.div-table-card-detail {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.cancal {
    width: auto;
    height: fit-content;
    font-size: 1.2rem;
    color: #fff;
    background-color: red;
    border: none;
    border-radius: 0.3rem;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
}

.cancal:hover {
    cursor: pointer;
    background-color: #b8358d;
}

.submit {
    width: auto;
    height: fit-content;
    font-size: 1.2rem;
    color: #fff;
    background-color: #467ac8;
    border: none;
    border-radius: 0.3rem;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
}

.submit:hover {
    cursor: pointer;
    background-color: #b8358d;
}