html,
body {
  height: 100%;
}

.timer {
  font-size: 6rem;
  color: #333;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.date {
  font-size: 1.7rem;
  color: #333;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.ic-login {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #333;
}

.input-place,
.button-blue {
  width: 200px;
  display: block;
}

.input-place {
  margin-bottom: 5px;
  border-radius: 8px;
}

.input-login {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button-blue,
.button-blue-2 {
  font-size: 1.3rem;
  background-color: #467ac8;
  color: #fff;
  border-radius: 8px;
  border: none;
  padding: 7px 0;
  text-align: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.button-blue:hover {
  transition-duration: 0.1s;
  transform: scale(1.1);
  box-shadow: 4px 4px 1px #cfcfdd;
  cursor: pointer;
}

.button-blue:focus {
  transition-duration: 0.1s;
  transform: scale(1.1);
  border: none;
  box-shadow: 4px 4px 1px #cfcfdd;
  cursor: pointer;
}

.button-blue-2:hover {
  transition-duration: 0.1s;
  transform: scale(1.1);
  cursor: pointer;
}

.button-blue-2:focus {
  transition-duration: 0.1s;
  transform: scale(1.1);
  border: none;
  cursor: pointer;
}

.input-with-icon {
  position: relative;
  margin-bottom: 10px;
}

.input-with-icon input {
  padding-left: 35px;
}

.input-with-icon input:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 10px 0px #467ac8;
}

.input-with-icon .ic {
  position: absolute;
  top: 10px;
  left: 10px;
  color: black;
}

.Divbody {
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 1) 0%,
    rgba(240, 240, 240, 1) 50%,
    rgba(225, 225, 225, 1) 100%
  );
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.ocean {
  /* height: 4%; */
  width: 100%;
  background: #eee;
  position: relative;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.wave {
  background: url("../../../assets/image/wave.png") repeat-x;
  position: absolute;
  top: -175px;
  width: 6400px;
  height: 200px;
  animation: wave 7s cubic-bezier(0.35, 0.45, 0.65, 0.55) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 6.5s cubic-bezier(0.3, 0.4, 0.6, 0.5) -0.15s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }

  50% {
    transform: translate3d(0, 5px, 0);
  }
}

@media screen and (max-width: 1024px) and (min-width: 992px) {
}

@media screen and (max-width: 991px) {
  .button-blue {
    background-color: #467ac8;
    color: #fff;
    border-radius: 8px;
    border: none;
    padding: 7px 0;
    text-align: center;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    z-index: 99;
  }

  .button-blue:hover {
    transition-duration: 0.1s;
    transform: scale(1.1);
    box-shadow: 4px 4px 1px #cfcfdd;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  .button-blue:focus {
    transition-duration: 0.1s;
    transform: scale(1.1);
    border: none;
    box-shadow: 4px 4px 1px #cfcfdd;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
}
