.div-modal-change-status {
     width: 315px;
     border-radius: 10px;
     background-color: #fff;
     padding: 20px 0;
     font-size: 12px;
     position: relative;
}
@media only screen and (max-width: 767px) {
     .div-modal-settime {
          width: 95%;
          min-width: 320px;
          border-radius: 10px;
          background-color: #fff;
          padding: 20px 0;
          font-size: 12px;
          position: relative;
     }
}
@media only screen and (min-width: 768px) {
     .div-modal-settime {
          width: 80%;
          min-width: 520px;
          border-radius: 10px;
          background-color: #fff;
          padding: 20px 0;
          font-size: 12px;
          position: relative;
     }
}

.label-modal-change-status {
     font-size: 2rem;
     font-weight: bold;
     color: #467ac8;
     white-space: break-spaces;
}

.button-close-modal-change-status {
     position: absolute;
     background-color: #467ac8;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 100%;
     width: 30px;
     height: 30px;
     top: 20px;
     right: 20px;
     margin: -10px -10px 0 0;
}

.label-modal-status-radio {
     font-size: 1.4rem;

     color: #000;
}
.auto-style .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
     background: white !important;
     font-size: 1.3em !important;
 }
 .auto-style .MuiAutocomplete-root {
     width: 100% !important;
 }
 .auto-style .MuiSvgIcon-root {
     font-size: x-large !important;
 }
 .auto-style .MuiChip-root {
     font-size: 1em !important;
     height: 28px !important;
 }