.main {
	padding: 0.5vw;
}

.app-image-gallery {
	display: flex;
	justify-content: center;
}

.ImgBanner {
	width: 100% !important;
	height: 100% !important;
}

.image-gallery-slide img {
	width: 100% !important;
	height: 100% !important;
	object-fit: cover;
}
.custom-file-upload {
	border: 1px solid #ccc;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}
