@font-face {
     font-family: "sukhumvit-semiBold";
     src: url("../../assets/fonts/Sarabun-Bold.ttf");
     /* unicode-range: U+0E00- U+0E7F; */
}

@font-face {
     font-family: "sukhumvit-light";
     src: url("../../assets/fonts/Sarabun-Light.ttf");
     /* unicode-range: U+0E00- U+0E7F; */
}

@font-face {
     font-family: "sukhumvit-medium";
     src: url("../../assets/fonts/Sarabun-Medium.ttf");
}

@font-face {
     font-family: "sukhumvit-text";
     src: url("../../assets/fonts/Sarabun-Regular.ttf");
}
