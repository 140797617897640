.LabelType {
    font-size: 1.5rem;
}

.FormType {
    /* display: block; */
    width: 100%;
    height: 100%;
    padding: 0.8rem 0.8rem;
    font-size: 1.1rem;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}