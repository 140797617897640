.div-history-table-mt {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.label-history-subject-mt {
    color: #000;
    font-size: 1.4rem;
    font-weight: bold;
}

.label-history-list-mt {
    color: #000;
    font-size: 14px;
    font-weight: normal;
}