.loading {
  width: 100%;
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search {
  position: relative;
  margin-bottom: 0;
}

.search input {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search .ic {
  position: absolute;
  top: 11px;
  left: 0.8rem;
  color: #ccc;
  width: 1rem;
  height: 1rem;
}

.LabelTodayTable {
  font-size: 1.8rem;
  color: #467ac8;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-all-table {
  color: #fff;
  background-color: #467ac8;
  border-color: #467ac8;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-all-table:hover {
  cursor: pointer;
  color: #fff;
  background-color: #b8358d;
  text-decoration: none;
}

.btn-all-table:focus {
  outline: none;
}

.btn-delete {
  color: #fff;
  background-color: #e25050;
  border-color: #e25050;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-delete:hover {
  cursor: pointer;
  color: #fff;
  background-color: #e02d2d;
  text-decoration: none;
}

.btn-delete:focus {
  outline: none;
}

.fix-col {
  /* flex: 0 !important */

  flex: auto !important;
  width: auto !important;
  max-width: 100% !important;
}

.button-blue-hover:hover {
  background-color: #b8358d !important;
  transition-duration: 0.2s;
}

.button-gray-hover:hover {
  background-color: #a0a0a0 !important;
  transition-duration: 0.2s;
}

.button-green-hover:hover {
  background-color: #66bfb8 !important;
  transition-duration: 0.2s;
}
