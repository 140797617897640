.row-btn-program {
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-between;
     margin: 0.5rem 0;
}

.ic-btn-pdf-subject {
     width: 2vw;
     height: 2vw;
     color: #fff;
     margin-right: 1rem;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
     cursor: pointer;
}

.row-btn-all-program {
     display: flex;
     flex-direction: column;
     align-items: flex-end;
     justify-content: flex-end;
}

.ic-btn-green-subject {
     width: 1.5rem;
     height: 1.5rem;
     color: #fff;
     margin-right: 1rem;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
}

.btn-green-subject {
     height: 100%;
     font-size: 1.5rem;
     font-weight: 500;
     color: #fff;
     background-color: #7bc8c6;
     border: none;
     border-radius: 0.3rem;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.8rem 1.5rem;
     margin-left: 1.5rem;
}

.btn-red-subject {
     height: 100%;
     font-size: 1.5rem;
     font-weight: 500;
     color: #fff;
     background-color: tomato;
     border: none;
     border-radius: 0.3rem;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.8rem 1.5rem;
     margin-left: 1.5rem;
}

.btn-green-subject:hover {
     cursor: pointer;
     background-color: #66bfb8;
}

.btn-red-subject:hover {
     cursor: pointer;
     background-color: red;
}

.div-table-program-all {
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     margin-top: 1rem;
     margin-bottom: 2rem;
}

.div-table-program-detail {
     width: 100%;
     height: auto;
     display: flex;
     flex-direction: row;
     /* background-color: #467ac8; */
     background: linear-gradient(to right, #467ac8, #467ac8cc);
     border: none;
     padding: 0.75rem;
     border-radius: 1rem 1rem 0 0;
     cursor: pointer;
}

.div-table-program-list {
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     background-color: #fff;
     border: none;
     padding: 0.75rem;
     border-radius: 0 0 1rem 1rem;
}

.div-subject-list-checkbox {
     color: #fff;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
}

.div-subject-list {
     color: #fff;
}

.div-treatment-list {
     color: #000;
     font-size: 13px;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     margin: 0.25vh 0;
}

.font-style-color-wht {
     font-size: 14px;
     font-weight: normal;
     color: #fff;
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     user-select: none;
}

.div-treatment-list-result {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
}

.btn-history-treatment {
     width: 100%;
     height: auto;
     display: flex;
     flex-direction: row;
     background-color: #467ac8;
     color: #fff;
     border: none;
     padding: 0.5rem;
     border-radius: 1rem;
     justify-content: center;
     align-items: center;
}

.btn-history-treatment:hover {
     cursor: pointer;
     background-color: #44a7d8;
}

.label-font-result-list {
     color: #000;
     font-size: 14px;
     margin: 0;
}

@media only screen and (max-width: 369px) {
     #nav-link-left {
          background: #8db9f9;
          border: none;
          border-top-left-radius: 12px;
          border-top-right-radius: 0px;
          /* width: 150px; */
          text-align: center;
          margin: unset;
          cursor: pointer;
     }

     #nav-link-active-left {
          background: linear-gradient(to right, #6892d1, #4f81ca);
          border: none;
          border-top-right-radius: 12px;
          border-top-left-radius: 0px;
          /* width: 150px; */
          text-align: center;
          margin: unset;
          cursor: pointer;
     }

     #nav-link-right {
          background: #8db9f9;
          border: none;
          border-top-left-radius: 0px;
          border-top-right-radius: 12px;
          /* width: 150px; */
          text-align: center;
          margin: unset;
          cursor: pointer;
     }

     #nav-link-active-right {
          background: linear-gradient(to right, #4f81ca, #6892d1);
          border: none;
          border-top-left-radius: 12px;
          border-top-right-radius: 0px;
          /* width: 150px; */
          text-align: center;
          margin: unset;
          cursor: pointer;
     }
}

@media only screen and (min-width: 370px) {
     #nav-link-left {
          background: #8db9f9;
          border: none;
          border-top-left-radius: 12px;
          border-top-right-radius: 0px;
          /* width: 150px; */
          text-align: center;
          margin: unset;
          cursor: pointer;
     }

     #nav-link-active-left {
          background: linear-gradient(to right, #6892d1, #4f81ca);
          border: none;
          border-top-right-radius: 12px;
          border-top-left-radius: 0px;
          /* width: 150px; */
          text-align: center;
          margin: unset;
          cursor: pointer;
     }

     #nav-link-right {
          background: #8db9f9;
          border: none;
          border-top-left-radius: 0px;
          border-top-right-radius: 12px;
          /* width: 150px; */
          text-align: center;
          margin: unset;
          cursor: pointer;
     }

     #nav-link-active-right {
          background: linear-gradient(to right, #4f81ca, #6892d1);
          border: none;
          border-top-left-radius: 12px;
          border-top-right-radius: 0px;
          /* width: 150px; */
          text-align: center;
          margin: unset;
          cursor: pointer;
     }
}

@media only screen and (min-width: 401px) {
     #btnfoot {
          display: flex;
          justify-content: space-between;
     }
     #btnfootPT {
          display: flex;
          justify-content: flex-start;
     }
}

#nav-link-active-right-pt {
     background: linear-gradient(to right, #4f81ca, #6892d1);
     border: none;
     border-top-left-radius: 12px;
     border-top-right-radius: 12px;
     margin: unset;
     cursor: pointer;
}

#border-bottom {
     border-bottom: 1px solid #ffffff;
     /* height: 36.35px; */
     display: block;
}

.nav-tabs .nav-item {
     margin-bottom: -1px;
     width: 50%;
}
#edit-data-pt {
     background-color: #467ac8;
     font-size: 1.2rem;
     color: #fff;
     border: none;
     border-radius: 0.3rem;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.5rem 1rem;
     cursor: pointer;
     width: 100%;
}

#edit-data-pt:hover {
     background-color: #b8358d;
}
#edit-data-pt-time {
     background-color: rgb(123, 200, 198);
     font-size: 1.2rem;
     color: #fff;
     border: none;
     border-radius: 0.3rem;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.5rem 1rem;
     cursor: pointer;
     width: 100%;
}
#edit-data-pt-time:hover {
     background-color: #b8358d;
}
#edit-data-pt-detail {
     background-color: green;
     font-size: 1.2rem;
     color: #fff;
     border: none;
     border-radius: 0.3rem;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.5rem 1rem;
     cursor: pointer;
     width: 100%;
}
#edit-data-pt-detail:hover {
     background-color: #b8358d;
}
#cancal-data-pt {
     background-color: red;
     font-size: 1.2rem;
     color: #fff;
     border: none;
     border-radius: 0.3rem;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.5rem 1rem;
     cursor: pointer;
     width: 100%;
}

#cancal-data-pt:hover {
     background-color: #b8358d;
}
@media only screen and (min-width: 401px) {
     #edit-data {
          background-color: rgb(123, 200, 198);
          font-size: 1.2rem;
          color: #fff;
          border: none;
          border-radius: 0.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.5rem 1rem;
          cursor: pointer;
          width: 150px;
     }

     #edit-data:hover {
          background-color: #b8358d;
     }

     #cancal-data {
          background-color: red;
          font-size: 1.2rem;
          color: #fff;
          border: none;
          border-radius: 0.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.5rem 1rem;
          cursor: pointer;
          width: 150px;
     }

     #cancal-data:hover {
          background-color: #b8358d;
     }

     #isPaid {
          background-color: rgb(70, 122, 200);
          color: #fff;
          border-color: transparent;
          width: 150px;
          font-size: small;
     }
}

@media only screen and (max-width: 400px) {
     #edit-data {
          background-color: rgb(123, 200, 198);
          font-size: 1rem;
          color: #fff;
          border: none;
          border-radius: 0.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.5rem 1rem;
          cursor: pointer;
     }

     #edit-data:hover {
          background-color: #b8358d;
     }

     #cancal-data {
          background-color: red;
          font-size: 1rem;
          color: #fff;
          border: none;
          border-radius: 0.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.5rem 1rem;
          cursor: pointer;
     }

     #cancal-data:hover {
          background-color: #b8358d;
     }

     #isPaid {
          background-color: rgb(70, 122, 200);
          color: #fff;
          border-color: transparent;
          width: 100%;
          font-size: 1rem;
          margin-top: 0.5rem;
     }
}
