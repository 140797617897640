.mainNav {
     padding: 0px;
     margin: 0px;
     height: 100%;
     background: linear-gradient(to right, #467ac8, #b8358d);
}

.navbar {
     padding: 0px !important;
     border-radius: 0px !important;
     border: unset !important;
}

.navbar-collapse {
     border-top: unset !important;
}

.ImgLogo {
     height: 4.5rem;
     padding: 0.5rem;
     object-fit: contain;
}

.Avatar {
     vertical-align: middle;
     margin-right: 1rem;
     width: 4.5rem;
     height: 4.5rem;
     padding: 0.5rem;
     object-fit: cover;
     border-radius: 50%;
}

.NavItem {
     color: #fff;
     font-size: 1.3rem;
}

.NavItem:hover {
     color: #b8358d;
     text-decoration: none;
     cursor: pointer;
}

.ButtonLogout {
     color: #fff;
     font-size: 1.3rem;
     text-align: center;
}

.ButtonLogout:hover {
     text-decoration: underline;
     cursor: pointer;
}

.NavLink {
     height: 100% !important;
     display: flex !important;
     padding: 0 20px !important;
     justify-content: center !important;
     align-items: center !important;
     /* font-size: smaller !important; */
}

.NavLink-S {
     height: 100% !important;
     display: flex !important;
     padding: 0 15px !important;
     justify-content: center !important;
     align-items: center !important;
     /* font-size: smaller !important; */
     text-align: center !important;
}

.NavLink-L {
     height: 100% !important;
     display: flex !important;
     padding: 0 20px !important;
     justify-content: center !important;
     align-items: center !important;
     /* font-size: smaller !important; */
     text-align: center !important;
}

.NavLink-Fontsize-L {
     height: 100% !important;
     display: flex !important;
     padding: 0 20px !important;
     justify-content: center !important;
     align-items: center !important;
     font-size: smaller !important;
     text-align: center !important;
}

.NavLink-Fontsize-S {
     height: 100% !important;
     display: flex !important;
     padding: 0 15px !important;
     justify-content: center !important;
     align-items: center !important;
     font-size: x-small !important;
     text-align: center !important;
}

.NavLink-active {
     font-weight: bold !important;
     text-decoration: underline !important;
     color: white !important;
}

.NavLinkMobile {
     font-size: 1.5rem;
     padding: 0.5rem 2.5rem !important;
}

.NavLinkMobile-active {
     font-weight: bold !important;
     text-decoration: underline !important;
     color: white !important;
}

.Noti-icon {
     width: 2rem;
     height: 2rem;
     color: white;
}

.Noti-icon:hover {
     cursor: pointer;
     transition-duration: 0.1s;
     transform: scale(1.1);
}

.Badge {
     position: absolute;
     top: -5px;
     left: 15px;
     color: white;
     background-color: #467ac8;
     height: 2rem;
     width: 2rem;
     border-radius: 50%;
     line-height: 2.1rem;
     font-size: 1.2rem;
     text-align: center;
     font-weight: 800;
     cursor: pointer;
}

@media only screen and (max-width: 767) {
     .navbar-brand {
          padding: 0px !important;
     }
}
