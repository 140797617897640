@import url("./style/font/fonts.css");

body {
     margin: 0;
     padding: 0;

     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
     font-family: "sukhumvit-semiBold";
}
div {
     font-family: "sukhumvit-medium";
}
.MuiInputBase-input {
     font-family: "sukhumvit-semiBold" !important;
}
.MuiTypography-root {
     font-family: "sukhumvit-medium" !important;
}
/* code {
} */

.sss {
     margin-bottom: 0 !important;
}

.size-label-1_5rem {
     font-size: 1.5em !important;
}
.size-label-1_25rem {
     font-size: 1.3em !important;
}
.form-control,
.div {
     font-size: 1.3em !important;
}
.label-user-detail {
     font-family: "sukhumvit-semiBold";
     font-size: 1.3em !important;
}
.swal-text,
.swal-title {
     text-align: center !important;
     white-space: break-spaces !important;
}
