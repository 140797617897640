.btn-form {
	color: #fff;
	background-color: #467ac8;
	border-color: #467ac8;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding: 0.375rem 3.75rem;
	font-size: 1.3rem;
	font-weight: 400;

	line-height: 1.5;
	border-radius: 0.25rem;
	-webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}

.btn-form:hover {
	cursor: pointer;
	color: #fff;
	background-color: #b8358d;
	text-decoration: none;
}

.btn-form:focus {
	outline: none;
}

.btn-exit-hover:hover {
	transition-duration: 0.2s;
	background-color: #b8358d !important;
}

.bed-table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border: 1px solid #ddd;
}

.bed-table th,
.bed-table td {
	text-align: left;
	padding: 8px;
}

.bed-table tr:nth-child(even) {
	background-color: #f2f2f2;
}

.box-input-user {
	padding: 1rem;
	border-radius: 10px;
	background-color: #fff;
}

.label-back {
	font-size: 1.4rem;
	margin-top: 0.5rem;
}

.label-add-user {
	font-size: 1.8rem;
	font-weight: bold;
	color: #467ac8;
}
/* .label-user-detail {
    font-size: 1.4rem;
} */

.input-user-detail {
	display: block;
	width: 100%;
	height: calc(2.25rem + 2px);
	padding: 0.375rem 0.75rem;

	font-size: 1vw;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	-webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-add-user {
	font-size: 1vw;
	font-weight: bold;
}

@media screen and (max-width: 991px) {
	.label-back {
		font-size: 1.3vh;
		margin-top: 10px;
	}

	.label-add-user {
		font-size: 1.8vh;
		font-weight: bold;
		color: #467ac8;
	}

	/* .label-user-detail {
		font-size: 1.5vh;
	} */

	.input-user-detail {
		display: block;
		width: 100%;
		height: calc(2.25rem + 2px);
		padding: 0.375rem 0.75rem;

		font-size: 1.3vh;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
		-webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}

	.btn-add-user {
		font-size: 1.3vh;
		font-weight: bold;
	}
}

@media screen and (max-width: 1024px) and (min-width: 992px) {
	.label-back {
		font-size: 1.3vw;
		margin-top: 10px;
	}

	.label-add-user {
		font-size: 1.8vw;
		font-weight: bold;
		color: #467ac8;
	}

	/* .label-user-detail {
		font-size: 1.3vw;
	} */

	.input-user-detail {
		display: block;
		width: 100%;
		height: calc(2.25rem + 2px);
		padding: 0.375rem 0.75rem;

		font-size: 1.3vw;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
		-webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}

	.btn-add-user {
		font-size: 1.3vw;
		font-weight: bold;
	}
}
