@media only screen and (min-width: 375px) {
     #btn-times-select {
          background-color: #228b22;
          border-color: #228b22;
     }

     #btn-select-date {
          background-color: #467ac8;
          border-color: #467ac8;
          outline: unset;
     }
     #btn-select-date:hover {
          background-color: #b7358dde;
          border-color: #b7358dde;
          outline: unset;
     }

     #btn-times-select-disable {
          color: #fff;
          background-color: red;
          border-color: red;
     }

     #btn-times-select:hover {
          background-color: #467ac8;
          border-color: #467ac8;
          outline: unset;
     }

     #btn-times-select-sed {
          background-color: #467ac8;
          border-color: #467ac8;
          outline: none;
     }

     #btn-times-select-sed:focus-within {
          background-color: #467ac8;
          border-color: #467ac8;
          color: #fff;
          outline: none;
     }
}

@media only screen and (max-width: 374px) {
     #btn-times-select {
          background-color: #228b22;
          border-color: #228b22;
          font-size: 9px;
     }

     #btn-select-date {
          background-color: #467ac8;
          border-color: #467ac8;
          outline: unset;
     }
     #btn-select-date:hover {
          background-color: #b7358dde;
          border-color: #b7358dde;
          outline: unset;
     }

     #btn-times-select-disable {
          color: #fff;
          background-color: red;
          border-color: red;
          font-size: 9px;
     }

     #btn-times-select:hover {
          background-color: #467ac8;
          border-color: #467ac8;
          outline: unset;
          font-size: 9px;
     }

     #btn-times-select-sed {
          background-color: #467ac8;
          border-color: #467ac8;
          outline: none;
          font-size: 9px;
     }

     #btn-times-select-sed:focus-within {
          background-color: #467ac8;
          border-color: #467ac8;
          color: #fff;
          outline: none;
          font-size: 9px;
     }
}

#btn-size-pen-dis {
     background-color: red;
     width: 0.1rem;
     height: 1.2rem;
     border-radius: 10rem;
     border: none;
}

#btn-size-pen {
     background-color: #ced4da;
     width: 0.1rem;
     height: 1.2rem;
     border-radius: 10rem;
     border: none;
}

#btn-size-pen-dis1 {
     background-color: red;
     width: 1.4rem;
     height: 1.4rem;
     border-radius: 10rem;
     border: none;
}

#btn-size-pen1 {
     background-color: #ced4da;
     width: 1.4rem;
     height: 1.4rem;
     border-radius: 10rem;
     border: none;
}

#btn-size-pen-dis2 {
     background-color: red;
     width: 1.6rem;
     height: 1.6rem;
     border-radius: 10rem;
     border: none;
}

#btn-size-pen2 {
     background-color: #ced4da;
     width: 1.6rem;
     height: 1.6rem;
     border-radius: 10rem;
     border: none;
}

#btn-size-pen-dis3 {
     background-color: red;
     width: 1.8rem;
     height: 1.8rem;
     border-radius: 10rem;
     border: none;
}

#btn-size-pen3 {
     background-color: #ced4da;
     width: 1.8rem;
     height: 1.8rem;
     border-radius: 10rem;
     border: none;
}

#btn-FF0000 {
     background-color: #ff0000;
     width: 1.6rem;
     height: 1.6rem;
     border-radius: 10rem;
     border: none;
}

#btn-FFA500 {
     background-color: #ffa500;
     width: 1.6rem;
     height: 1.6rem;
     border-radius: 10rem;
     border: none;
}

#btn-FFFF00 {
     background-color: #ffff00;
     width: 1.6rem;
     height: 1.6rem;
     border-radius: 10rem;
     border: none;
}

#btn-008000 {
     background-color: #008000;
     width: 1.6rem;
     height: 1.6rem;
     border-radius: 10rem;
     border: none;
}

#btn-0000FF {
     background-color: #0000ff;
     width: 1.6rem;
     height: 1.6rem;
     border-radius: 10rem;
     border: none;
}

#btn-4B0082 {
     background-color: #4b0082;
     width: 1.6rem;
     height: 1.6rem;
     border-radius: 10rem;
     border: none;
}

#btn-EE82EE {
     background-color: #ee82ee;
     width: 1.6rem;
     height: 1.6rem;
     border-radius: 10rem;
     border: none;
}

@media only screen and (max-width: 322px) {
     .selectDoc {
          color: #467ac8;
          font-size: 1.2rem;
          /* text-decoration: underline; */
          cursor: pointer;
     }
}

@media only screen and (min-width: 323px) and (max-width: 499px) {
     .selectDoc {
          color: #467ac8;
          font-size: small;
          /* text-decoration: underline; */
          cursor: pointer;
     }
}

@media only screen and (min-width: 500px) {
     .selectDoc {
          color: #467ac8;
          font-size: medium;
          /* text-decoration: underline; */
          cursor: pointer;
     }
}

@media only screen and (max-width: 449px) {
     .headselect {
          text-align: center;
     }
}

@media only screen and (min-width: 450px) {
     .headselect {
          display: flex;
          justify-content: space-between;
     }
}

@media only screen and (min-width: 576px) {
     .massage {
          text-align: center;
     }
}

@media only screen and (max-width: 575px) {
     .massage {
          text-align: center;
          margin-top: 0.5rem;
     }
}

.ortho {
     text-align: center;
     margin-top: 0.5rem;
}

.progress-chat {
     border: 1px solid #ccc;
     border-radius: 0.5rem;
}

.progress-chat-body {
     /* height: auto; */
     height: 55vh;
     overflow-y: scroll;
     background-color: #f5f5f5;
}
