@import url("./style/font/fonts.css");

.App {
     background: radial-gradient(ellipse at center, rgb(255, 255, 255) 0%, rgb(247.5, 247.5, 247.5) 50%, rgb(240, 240, 240) 100%) !important;
     min-height: 100vh;
     display: flex;
     flex-direction: column;
}

.LabelTodayTable {
     font-family: "sukhumvit-semiBold";
}

.LabelType {
     font-family: "sukhumvit-semiBold";
}

.table-striped {
     font-family: "sukhumvit-light";
}

.btn-all-table {
     font-family: "sukhumvit-medium";
}

.sub-income-input {
     font-family: "sukhumvit-light";
}

.text-muted {
     font-family: "sukhumvit-light";
}

.form-control {
     font-family: "sukhumvit-light";
}

.d-flex {
     font-family: "sukhumvit-semiBold";
}

.m-2 {
     font-family: "sukhumvit-light";
}

.col-form-label {
     font-family: "sukhumvit-semiBold";
}

.top-label {
     font-size: 1.2rem;
     width: 12rem;
}

.top-input {
     max-width: 20rem !important;
}

.income-label {
     font-size: 1.1rem;
     width: 10rem;
}

.income-input {
     max-width: 20rem !important;
}

.sub-income-label {
     font-size: 1.1rem;
     width: 4rem;
}

.sub-income-input {
     max-width: 10rem !important;
}

.hidden {
     width: 0.1px;
     height: 0.1px;
     opacity: 1;
     overflow: hidden;
     position: absolute;
     z-index: 100;
}

@media screen and (min-width: 768px) {
     .table-bordered {
          border: 1px solid #dee2e6 !important;
     }
}

/* @media only screen and(min-width: 1900px) and (max-width: 1920px) { */
.sympon-width-hight {
     width: 100%;
     height: auto;
     display: flex;
     justify-content: center;
}

/* } */

/* Handle on hover
.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: grey;
} */

/* Set class Row display flex Safari */
/* .row:before {
  content: none;
}
.row:after {
  content: '';
} 
or
*/
.row:before,
.row:after {
     display: flex !important;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
     line-height: initial;
}
.table > thead > tr > th {
     white-space: pre;
}
