.table-add-tm {
     background-color: white !important;
}
.add-tm .table {
     font-size: 1.5em !important;
     /* border-collapse: collapse !important; */
}
.add-tm .table th {
     background-color: #f5f5f5 !important;
}
.add-tm .table tbody tr {
     height: 60px !important;
}
/* .table-add-tm { */
/* overflow: auto; */
/* height: 100px; */
/* width: 240px; */
/* } */
/* .add-pt {
     height: calc(50vh - 407px) !important;
} */
.add-pt .MuiTableCell-root {
     border: 1px solid rgba(224, 224, 224, 1) !important;
}
.add-pt .MuiTableHead-root .MuiTableRow-root {
     height: 40px !important;
}
.add-pt .MuiTableBody-root .MuiTableRow-root {
     height: 80px !important;
}

/* .add-pt .table {
     font-size: 1.5em !important;
     border-collapse: collapse !important;
}
.add-pt .table th {
     background-color: #f5f5f5 !important;
     width: 300px !important;
}
.add-pt .table tbody tr {
     height: 100px !important;
     width: 450px !important;
}
.add-pt thead th {
     position: sticky;
     top: 0;
     z-index: 1;
}
.add-pt tbody th {
     position: sticky;
     left: -1px;
} */

.grid-date-field .MuiInputBase-root {
     color: #263238;
     cursor: pointer !important;
     display: inline-flex;
     position: relative;
     font-size: 1.4em;
     box-sizing: border-box;
     align-items: center;
     font-family: Prompt;
     font-weight: 400;
     line-height: 1.1876em;
     border: 1px solid #c4c4c4;
     padding-left: 11px;
     height: 25px;
     /* width: 180px; */
     /* max-width: 180px; */
     border-radius: 3px;
     padding-top: 3px;
}
.grid-date-field .MuiInputBase-root input {
     cursor: pointer !important;
}
.grid-date-field .MuiInput-underline:before {
     left: 0;
     right: 0;
     bottom: 0;
     content: "\00a0";
     position: absolute;
     transition: unset;
     border-bottom: none;
     pointer-events: none;
}
.grid-date-field .MuiInput-underline.Mui-disabled:before {
     border-bottom-style: unset !important;
}
.grid-date-field .MuiInputBase-root.Mui-disabled {
     color: rgba(0, 0, 0, 0.38);
     cursor: default;
     background: white !important;
}
/*------------------- grid-text-field -------------------*/
.grid-text-field .MuiIconButton-label {
     color: #602d30;
}
.grid-text-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
     border-color: #602d30 !important;
     border-width: 1px !important;
}
.grid-text-field .MuiOutlinedInput-notchedOutline {
     border-color: #602d30;
}
.grid-text-field .MuiOutlinedInput-notchedOutline:focus {
     outline: unset !important;
}
.grid-text-field .MuiInputBase-input.Mui-disabled {
     opacity: 1;
     background: white !important;
}
.grid-text-field .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
     border-color: #602d30;
}
.grid-text-field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
     border-color: #602d30 !important;
}
.MuiInput-underline:after {
     border-bottom: none !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
     border-bottom: none !important;
}
.MuiInput-underline:focus:not(.Mui-disabled):before {
     border-bottom: none !important;
}
.grid-text-field .MuiInputBase-root.Mui-disabled {
     color: rgba(0, 0, 0, 0.38);
     cursor: default;
     background: white !important;
}
.MuiTypography-body2,
.MuiTypography-body1 {
     font-size: 2em !important;
}
.MuiTypography-subtitle1 {
     font-size: 1.5em !important;
}
.MuiTypography-subtitle2 {
     font-size: 1.7em !important;
}
.MuiTypography-caption {
     font-size: 1.2em !important;
     color: black !important;
}
/* ------------ focut unset ------------- */
.MuiContainer-root:focus {
     outline: unset !important;
}

.modal-outline-unset:focus {
     outline: unset !important;
}

button:focus {
     outline: unset !important;
}
